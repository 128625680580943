import React from 'react'
import AbContent from '../components/abContent'
import AbLayout from '../components/AbLayout'
import { PageModelTitle } from '../model/PageModel'

export default Ab

function Ab() {
  let pageModel = new PageModelTitle('New is migrating to AB', 'ab-html')
  return <div>{AbLayout(Main(), pageModel)}</div>
}

function Main() {
  return (
    <div>
      <AbContent />
    </div>
  )
}

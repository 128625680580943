import React from 'react'
import newtonLogo from '../static/images/newton-logo.png'
import abLogo from '../static/images/logo.png'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'

export default function AbContent() {
  const intl = useIntl()

  return (
    <div id={'ab'}>
      <div className={'ab-banner-pc container'}>
        <div className={'ab-banner-centent'}>
          <div className={'banner-icon'}>
            <img src={newtonLogo} alt='logo' />
            <img src={abLogo} alt='logo' className={'right-icon'} />
          </div>
          <div className={'banner-title'}>
            $NEW→<a href='https://ab.org'>$AB</a>
          </div>
          <div className={'banner-sub-title'}>
            Newton is migrating to <a href='https://ab.org'>AB</a>
            <p>February 8, 2025</p>
          </div>
        </div>
      </div>
      <div className='ab-content'>
        <h2>Newton Project</h2>
        <p>Newton Project, launched in 2018,  the primary goal of the Newton Project was to build infrastructure for the Community Economy—a vision aimed at empowering individuals by ensuring that <b>"everyone should benefit directly from economic growth!"</b></p>
        <p>Over the years, the project has made significant progress toward achieving this goal. By providing a decentralized infrastructure that supports the community economy, with its first phase now completed, the project is ready to embark on a new journey.</p>
        
        <h2>Newton DAO</h2>
        <p>In January 2025, Newton DAO was established as a decentralized autonomous organization (DAO) composed of all NEW token holders. This marked a significant transformation for the Newton Project, signaling its evolution to a fully decentralized stage.</p>
        <p>Newton DAO represents a governance model where decisions are made collectively by the community, with no central "project team" or authority. This milestone reflects the project's commitment to decentralization and community-driven development. The establishment of Newton DAO highlights the project's growth and its ability to adapt to the ever-changing blockchain landscape.</p>
        
        <h2>Exploring the Future</h2>
        <p>With blockchain technology now mature and diverse applications flourishing, the Newton DAO community has collectively decided to pivot its focus toward the future. The project's next phase will center on building infrastructure for heterogeneous blockchains and assets. By integrating assets with advanced technology, the project aims to create a rich ecosystem of applications that address real-world needs.</p>
        <p>As part of this strategic shift, the Newton Project will <b>rebrand</b> as <b>AB</b> to reflect its new direction and purpose.</p>
        
        <h2>Stay Updated</h2>
        <p>The transition from Newton Project to AB represents a bold new chapter in the project's journey. We invite everyone to stay informed and engaged as we move forward. For the latest updates, visit our new official website: <a href='https://ab.org'>https://ab.org</a>.</p>
        <p>Together, we will continue to innovate and create a better future with blockchain technology!</p>
      </div>
      <div className='ab-footer'>
        <p>Copyright © 2025 Newton DAO. All Rights Reserved.</p>
      </div>
    </div>
  )
}
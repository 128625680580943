import React, { useState } from 'react'
import { useIntl, IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl-v6'
import { BsArrowRightShort } from 'react-icons/bs'
import { languageName } from '../utils/language'
import { intlUrl } from '../utils/url'

export default function Language() {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)

  const handleLanguageChange = (language: string) => {
    changeLocale(language);
    // localStorage.setItem("usedLanguage", language)
  }

  return (
    <>
      <div className={'language'}>
        <span 
          className={'language-img'}
          onClick={() => setIsOpen(!isOpen)}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        ></span>
        {/* <div> */}
          <div 
            className={`language-box transition-opacity ${
              isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <div className={'language-item'}>
              <IntlContextConsumer>
                {({ languages, language: currentLocale }: any) =>
                  languages.map((language: any) => (
                    <span
                      key={language}
                      onClick={() => handleLanguageChange(language)}
                      className={currentLocale === language ? 'current' : ''}
                    >
                      {languageName[language]}
                    </span>
                  ))
                }
              </IntlContextConsumer>
            </div>
            {/* <a className={'language-github'} href={intlUrl} target="_blank">
              Participate in building internationalization
              <BsArrowRightShort />
            </a> */}
          </div>
        </div>
        {/* <p>{intl.formatMessage({ id: 'Languages' })}</p> */}
      {/* </div> */}
    </>
  )
}

import React from 'react'
import Language from './languages'
import { Link } from 'gatsby-plugin-intl-v6'
import newtoHeader from '../static/images/newton-header.png'

export default function AbHeader() {

  return (
    <>
      <div className={'header'}>
        <div className={'header-logo'}>
          <div>
            <Link to="/">
              <img className="w-32" alt="logo" src={newtoHeader} />
            </Link>
          </div>
          <div className={'header-search'}>
            <Language />
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import AbHeader from './abHeader'
import Seo from './seo'
import { PageModelTitle } from '../model/PageModel'

export default function NormalLayout(children: React.ReactNode, pageModel: PageModelTitle): JSX.Element {
  return (
    <div className={pageModel.name}>
      <Seo title={pageModel.title} description={''} meta={[]} lang={''} />
      <AbHeader />
      {children}
    </div>
  )
}